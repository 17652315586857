<template>
    <div class="contenedor-inicio row">
        <div class="contenedor-cards-money d-flex justify-content-around align-items-start">
            <div class="card-money d-flex justify-content-center align-items-center flex-column">
                <h1 class="texto-card-money">Utilidad</h1>
                <p class="texto-cantidad-card-money">${{utilidad}}</p>
            </div>
            <div class="card-money d-flex justify-content-center align-items-center flex-column">
                <h1 class="texto-card-money">Por pagar</h1>
                <p class="texto-cantidad-card-money">${{porPagar}}</p>
            </div>
            <div class="card-money d-flex justify-content-center align-items-center flex-column">
                <h1 class="texto-card-money">Por cobrar</h1>
                <p class="texto-cantidad-card-money">${{porCobrar}}</p>
            </div>
        </div>
        <div class="contenedor-derecha d-flex justify-content-start align-items-center flex-column">
            <div class="contenedor-resumen d-flex justify-content-start align-items-center flex-column">
                <h1 class="texto-card-resumen">Resumen</h1>
                <p class="texto-nombre-card-resumen">{{nombreGestion}}</p>
                <div class="row">
                    <div class="col-5">
                        <p class="texto-resumen">Provedores: </p>
                        <p class="texto-resumen">Clientes: </p>
                        <p class="texto-resumen">Productos: </p>
                        <p class="texto-resumen">Bancos: </p>
                        <p class="texto-resumen">Cajas: </p>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-5">
                        <p class="texto-resumen">{{provedores}}</p>
                        <p class="texto-resumen">{{clientes}}</p>
                        <p class="texto-resumen">{{productos}}</p>
                        <p class="texto-resumen">{{bancos}}</p>
                        <p class="texto-resumen">{{cajas}}</p>
                    </div> 
                </div>
            </div>
            <div class="boton-compra d-flex justify-content-center align-items-center" @click="abrirInterfazCompra">Compra</div>
            <div class="boton-venta d-flex justify-content-center align-items-center" @click="abrirInterfazVenta">Venta</div>
            <div class="boton-exportar d-flex justify-content-center align-items-center" @click="abrirInterfazExportar">Exportar</div>
        </div>
        <div class="contenedor-cuenta-historial d-flex justify-content-start align-items-center flex-column">
            <div class="caja-filtro d-flex justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`" @click="abrirFiltros">Filtros<div class="simboloIzquierda" :style="`transform: rotateZ(${izquierdaDesplegable}deg);`"></div><div class="simboloDerecha" :style="`transform: rotateZ(${derechaDesplegable}deg);`"></div></div>
            <div class="caja-filtros d-flex justify-content-around align-items-center flex-row flex-wrap" :style="`height:${alturaFiltro}px; padding-top:${paddingTop}px;`">
                <div class="col-2 margin-izquierda">
                    <label for="desde" class="label">Desde</label>
                    <input type="date" id="desde" class="input" v-model="desde" :max="hasta" @change="actualizarFiltros"/>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="hasta" class="label">Hasta</label>
                    <input type="date" id="hasta" class="input" v-model="hasta" :min="desde" @change="actualizarFiltros" />
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="tipoInicio" class="label">Tipo</label>
                    <select name="" id="tipoInicio" class="input" v-model="tipo" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="tipos in tiposs" v-bind:key="tipos.id" v-bind:value="tipos">{{tipos}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="productoInicio" class="label">Producto</label>
                    <select name="" id="productoInicio" class="input" v-model="producto" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="productos in productoss" v-bind:key="productos.id" v-bind:value="productos">{{productos}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="provedorInicio" class="label">Provedor</label>
                    <select name="" id="provedorInicio" class="input" v-model="provedor" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="provedores in provedoress" v-bind:key="provedores.id" v-bind:value="provedores">{{provedores}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="bancoInicio" class="label">Banco</label>
                    <select name="" id="bancoInicio" class="input" v-model="banco" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="bancos in bancoss" v-bind:key="bancos.id" v-bind:value="bancos">{{bancos}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="cajaInicio" class="label">Caja</label>
                    <select name="" id="cajaInicio" class="input" v-model="caja" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="cajas in cajass" v-bind:key="cajas.id" v-bind:value="cajas">{{cajas}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="clienteInicio" class="label">Cliente</label>
                    <select name="" id="clienteInicio" class="input" v-model="cliente" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="clientes in clientess" v-bind:key="clientes.id" v-bind:value="clientes">{{clientes}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="estadoInicio" class="label">Estado</label>
                    <select name="" id="estadoInicio" class="input" v-model="estado" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="estados in estadoss" v-bind:key="estados.id" v-bind:value="estados">{{estados}}</option>
                    </select>
                </div>
            </div>
            <div class="caja-enunciado"></div>
        </div>
        <div class="interfaz-compra d-flex justify-content-start align-items-center flex-column" v-if="bulInterfazCompra1 === true">
            <div class="indicador-posicion d-flex justify-content-center align-items-center">
                <div class="paso1 d-flex justify-content-center align-items-center">1</div>
                <div class="linea"></div>
                <div class="paso2 d-flex justify-content-center align-items-center">2</div>
            </div>
            <form class="card-compra row" v-on:submit.prevent>
                <div class="x-compra" @click="abrirInterfazCompra"></div>
                <h1 class="texto-compra">Compra</h1>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column">
                    <label for="provedor" class="label2">Provedor</label>
                    <input type="text" id="provedor" class="input2" v-model="provedorCompra" />
                    <label for="numeroC" class="label2">Numero de control</label>
                    <input type="text" id="numeroC" class="input2" v-model="numeroControlCompra" />
                    <label for="tipoG" class="label2">Tipo de compra (Gupos)</label>
                    <input type="text" id="tipoG" class="input2" v-model="tipoCompra" />
                    <label for="coment" class="label2">Comentarios</label>
                    <input type="text" id="coment" class="input2" v-model="comentariosCompra" />
                </div>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column">
                    <label for="Cc" class="label2">Código contable</label>
                    <input type="text" id="Cc" class="input2" v-model="codigoContableCompra" />
                    <label for="almacen" class="label2">Almacen</label>
                    <input type="text" id="almacen" class="input2" v-model="almacenCompra" />
                    <label for="fecha" class="label2">Fecha</label>
                    <input type="date" id="fecha" class="input2" v-model="fechaCompra" />
                    <label for="tipoR" class="label2">Tipo de registro</label>
                    <select name="" id="tipoR" class="input2" v-model="tipoRegistroCompra">
                            <option value="" ></option>
                            <option v-for="tipos in tipossCompra" v-bind:key="tipos.id" v-bind:value="tipos">{{tipos}}</option>
                    </select>
                </div>
                <h1 class="texto-compra2">Inventario</h1>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column mar-top">
                    <label for="nombreA" class="label2">Nombre del articulo</label>
                    <input type="text" id="nombreA" class="input2" v-model="nombreArticuloCompra" />
                    <label for="precio" class="label2">Precio</label>
                    <input type="text" id="precio" class="input2" v-model="precioCompra" />
                </div>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column mar-top">
                    <label for="cantidad" class="label2">Cantidad</label>
                    <input type="text" id="cantidad" class="input2" v-model="cantidadCompra" />
                    <label for="iva" class="label2">Tipo de I.V.A</label>
                    <input type="text" id="iva" class="input2" v-model="tipoIvaCompra" />
                </div>
                <h1 class="texto-compra3 d-flex justify-content-center">Base</h1>
                <h1 class="texto-compra4 d-flex justify-content-center">I.V.A</h1>
                <h1 class="texto-compra5 d-flex justify-content-center">Total compra</h1>
                <h1 class="texto-compra6 d-flex justify-content-center">{{baseValorCompra}}</h1>
                <h1 class="texto-compra7 d-flex justify-content-center">{{ivaValorCompra}}</h1>
                <h1 class="texto-compra8 d-flex justify-content-center">{{totalCompra}}</h1>
                <button class="button-compra d-flex justify-content-center align-items-center" @click="siguienApartadoCompra">Siguiente</button>
            </form>
        </div>
        <div class="interfaz-compra2 d-flex justify-content-start align-items-center flex-column" v-if="bulInterfazCompra2 === true">
            <div class="indicador-posicion d-flex justify-content-center align-items-center">
                <div class="paso2 d-flex justify-content-center align-items-center">1</div>
                <div class="linea2"></div>
                <div class="paso1 d-flex justify-content-center align-items-center">2</div>
            </div>
            <div class="card-compra2 row d-flex justify-content-start align-items-center flex-column">
                <div class="x-compra" @click="abrirInterfazCompra"></div>
                <h1 class="texto-compra">Compra</h1>
                <div></div>
                <div class="col-12">
                    <label for="condiciones" class="label3">Condiciones de pago</label>
                    <select name="" id="condiciones" class="input3" v-model="condicionesPCompra">
                            <option value="" ></option>
                            <option v-for="condiciones in condicionessCompra" v-bind:key="condiciones.id" v-bind:value="condiciones">{{condiciones}}</option>
                    </select>
                </div>
                <div class="col-12" v-if="condicionesPCompra === 'credito'">
                    <label for="fechav" class="label3">Fecha de vencimiento</label>
                    <input type="date" id="fechav" class="input3" v-model="fechaVCompra" />
                </div>
                <div class="col-12" v-if="condicionesPCompra === 'contado'">
                    <label for="formap" class="label3">Forma de pago</label>
                    <select name="" id="formap" class="input3" v-model="formaPCompra">
                            <option value="" ></option>
                            <option v-for="formas in formassCompra" v-bind:key="formas.id" v-bind:value="formas">{{formas}}</option>
                    </select>
                </div>
                <div class="col-12" v-if="formaPCompra === 'caja'">
                    <label for="Tcaja" class="label3">Caja</label>
                    <input type="text" id="Tcaja" class="input3" v-model="TcajaCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'cheque' || formaPCompra === 'transferencia'">
                    <label for="bancoC" class="label3">Banco</label>
                    <input type="text" id="bancoC" class="input3" v-model="bancoCCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'cheque'">
                    <label for="numeroR" class="label3">Numero de recibo</label>
                    <input type="text" id="numeroR" class="input3" v-model="numeroRCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'cheque'">
                    <label for="beneficiario" class="label3">Beneficiario</label>
                    <input type="text" id="beneficiario" class="input3" v-model="beneficiarioCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'cheque'">
                    <label for="fechaC" class="label3">Fecha del cheque</label>
                    <input type="text" id="fechaC" class="input3" v-model="fechaChequeCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'cheque'">
                    <label for="concept" class="label3">Concepto</label>
                    <input type="text" id="concept" class="input3" v-model="conceptoCompra" />
                </div>
                <div class="col-12" v-if="formaPCompra === 'transferencia'">
                    <label for="numeroT" class="label3">Numero de transferencia</label>
                    <input type="text" id="numeroT" class="input3" v-model="numeroTCompra" />
                </div>
                <div class="contenedor-botones-compra d-flex justify-content-around align-items-center">
                    <button class="button-compra2 d-flex justify-content-center align-items-center" @click="vistaAnteriorCompra">Anterior</button>
                    <button class="button-compra2 d-flex justify-content-center align-items-center">Agregar</button>
                </div>
            </div>
        </div>
        <div class="cuadro-estetico-compra" v-if="bulInterfazCompra1 === true || bulInterfazCompra2 === true"></div>
        <div class="interfaz-venta d-flex justify-content-start align-items-center flex-column" v-if="bulInterfazVenta1 === true">
            <div class="indicador-posicion d-flex justify-content-center align-items-center">
                <div class="paso1 d-flex justify-content-center align-items-center">1</div>
                <div class="linea"></div>
                <div class="paso2 d-flex justify-content-center align-items-center">2</div>
            </div>
            <form class="card-venta row" v-on:submit.prevent>
                <div class="x-venta" @click="abrirInterfazVenta"></div>
                <h1 class="texto-venta">Venta</h1>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column">
                    <label for="cliente" class="label2">Cliente</label>
                    <input type="text" id="cliente" class="input2" v-model="provedorVenta" />
                    <label for="numeroC" class="label2">Numero de control</label>
                    <input type="text" id="numeroC" class="input2" v-model="numeroControlVenta" />
                    <label for="tipoG" class="label2">Tipo de registro</label>
                    <select name="" id="tipoG" class="input2" v-model="tipoVenta">
                            <option value="" ></option>
                            <option v-for="tipos in tipossVenta" v-bind:key="tipos.id" v-bind:value="tipos">{{tipos}}</option>
                    </select>
                </div>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column">
                    <label for="Cc" class="label2">Código contable</label>
                    <input type="text" id="Cc" class="input2" v-model="codigoContableVenta" />
                    <label for="almacen" class="label2">Almacen</label>
                    <input type="text" id="almacen" class="input2" v-model="almacenVenta" />
                    <label for="fecha" class="label2">Fecha</label>
                    <input type="date" id="fecha" class="input2" v-model="fechaVenta" />
                </div>
                <div class="col-12 margin-subir">
                    <label for="coment" class="label3">Comentarios</label>
                    <input type="text" id="coment" class="input3" v-model="comentariosVenta" />
                </div>
                <h1 class="texto-venta10">Inventario</h1>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column mar-top">
                    <label for="nombreA" class="label2">Nombre del articulo</label>
                    <input type="text" id="nombreA" class="input2" v-model="nombreArticuloVenta" />
                    <label for="precio" class="label2">Precio</label>
                    <input type="text" id="precio" class="input2" v-model="precioVenta" />
                </div>
                <div class="col-6 d-flex justify-content-start align-items-start flex-column mar-top">
                    <label for="cantidad" class="label2">Cantidad</label>
                    <input type="text" id="cantidad" class="input2" v-model="cantidadVenta" />
                    <label for="iva" class="label2">Tipo de I.V.A</label>
                    <input type="text" id="iva" class="input2" v-model="tipoIvaVenta" />
                </div>
                <h1 class="texto-venta3 d-flex justify-content-center">Base</h1>
                <h1 class="texto-venta4 d-flex justify-content-center">I.V.A</h1>
                <h1 class="texto-venta5 d-flex justify-content-center">Total Venta</h1>
                <h1 class="texto-venta6 d-flex justify-content-center">{{baseValorVenta}}</h1>
                <h1 class="texto-venta7 d-flex justify-content-center">{{ivaValorVenta}}</h1>
                <h1 class="texto-venta8 d-flex justify-content-center">{{totalVenta}}</h1>
                <button class="button-venta d-flex justify-content-center align-items-center" @click="siguienApartadoVenta">Siguiente</button>
            </form>
        </div>
        <div class="interfaz-venta2 d-flex justify-content-start align-items-center flex-column" v-if="bulInterfazVenta2 === true">
            <div class="indicador-posicion d-flex justify-content-center align-items-center">
                <div class="paso2 d-flex justify-content-center align-items-center">1</div>
                <div class="linea2"></div>
                <div class="paso1 d-flex justify-content-center align-items-center">2</div>
            </div>
            <div class="card-venta2 row d-flex justify-content-start align-items-center flex-column">
                <div class="x-venta" @click="abrirInterfazVenta"></div>
                <h1 class="texto-venta">Venta</h1>
                <div class="col-12">
                    <label for="condiciones" class="label3">Condiciones de pago</label>
                    <select name="" id="condiciones" class="input3" v-model="condicionesPVenta">
                            <option value="" ></option>
                            <option v-for="condiciones in condicionessVenta" v-bind:key="condiciones.id" v-bind:value="condiciones">{{condiciones}}</option>
                    </select>
                </div>
                <div class="col-12" v-if="condicionesPVenta === 'credito'">
                    <label for="fechav" class="label3">Fecha de vencimiento</label>
                    <input type="date" id="fechav" class="input3" v-model="fechaVVenta" />
                </div>
                <div class="col-12" v-if="condicionesPVenta === 'contado'">
                    <label for="formap" class="label3">Forma de pago</label>
                    <select name="" id="formap" class="input3" v-model="formaPVenta">
                            <option value="" ></option>
                            <option v-for="formas in formassVenta" v-bind:key="formas.id" v-bind:value="formas">{{formas}}</option>
                    </select>
                </div>
                <div class="col-12" v-if="formaPVenta === 'caja'">
                    <label for="Tcaja" class="label3">Caja</label>
                    <input type="text" id="Tcaja" class="input3" v-model="TcajaVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'cheque' || formaPVenta === 'transferencia'">
                    <label for="bancoC" class="label3">Banco</label>
                    <input type="text" id="bancoC" class="input3" v-model="bancoCVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'cheque'">
                    <label for="numeroR" class="label3">Numero de recibo</label>
                    <input type="text" id="numeroR" class="input3" v-model="numeroRVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'cheque'">
                    <label for="beneficiario" class="label3">Beneficiario</label>
                    <input type="text" id="beneficiario" class="input3" v-model="beneficiarioVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'cheque'">
                    <label for="fechaC" class="label3">Fecha del cheque</label>
                    <input type="text" id="fechaC" class="input3" v-model="fechaChequeVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'cheque'">
                    <label for="concept" class="label3">Concepto</label>
                    <input type="text" id="concept" class="input3" v-model="conceptoVenta" />
                </div>
                <div class="col-12" v-if="formaPVenta === 'transferencia'">
                    <label for="numeroT" class="label3">Numero de transferencia</label>
                    <input type="text" id="numeroT" class="input3" v-model="numeroTVenta" />
                </div>
                <div class="contenedor-botones-venta d-flex justify-content-around align-items-center">
                    <button class="button-venta2 d-flex justify-content-center align-items-center" @click="vistaAnteriorVenta">Anterior</button>
                    <button class="button-venta2 d-flex justify-content-center align-items-center">Agregar</button>
                </div>
            </div>
        </div>
        <div class="cuadro-estetico-venta" v-if="bulInterfazVenta1 === true || bulInterfazVenta2 === true"></div>
        <div class="interfaz-exportar d-flex justify-content-start align-items-center flex-column" v-if="bulInterfazExportar === true">
            <div class="card-exportar row d-flex justify-content-start align-items-center flex-column">
                <div class="x-exportar" @click="abrirInterfazExportar"></div>
                <h1 class="texto-exportar">Exportar</h1>
                <div class="col-12">
                    <label for="filtradoCuenta" class="label3">Filtrado cuenta</label>
                    <input type="text" id="filtradoCuenta" class="input3" v-model="filtradoCuenta" />
                </div>
                <div class="col-12 d-flex flex-nowrap justify-content-around align-items-center">
                    <div class="cuadro-filtro-seleccionado" v-for="seleccion in seleccionFiltro" v-bind:key="seleccion.id" v-bind:value="seleccion">{{seleccion}}</div>
                </div>
                <div class="col-12">
                    <label for="formato" class="label3">Formato</label>
                    <input type="text" id="formato" class="input3" v-model="formato" />
                </div>
                <p class="exportar-informacion">Se va a exportar lo anteriormente filtrado en el apartado de cuenta, puedes guardar tambien estos informes</p>
                <div class="contenedor-botones-venta d-flex justify-content-around align-items-center">
                    <button class="button-exportar d-flex justify-content-center align-items-center">Descargar</button>
                    <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
                </div>
            </div>
        </div>
        <div class="cuadro-estetico-exportar" v-if="bulInterfazExportar === true"></div>
    </div>
</template>

<script>
    import Footer from "../../../components/Footer.vue"
    import {mapState} from "vuex"

    export default {
        name: "App",
        components: {
            Footer
        },
        data(){
            return{
                //datos de cochinito-cuenta resumen
                utilidad: 0,
                porPagar: 0,
                porCobrar: 0,
                nombreGestion: 'Promeplas',
                provedores: 0,
                clientes: 0,
                productos: 0,
                bancos: 0,
                cajas: 0,

                //deplegable filtro y variables filtro
                izquierdaDesplegable: 50,
                derechaDesplegable: -50,
                medidaBorde:10,
                alturaFiltro: 0,
                paddingTop: 0,
                desde:'',
                hasta:'',
                tipo: '',
                tiposs: this.tiposs = [],
                producto: '',
                productoss: this.productoss = [],
                provedor: '',
                provedoress: this.provedoress = [],
                banco: '',
                bancoss: this.bancoss = [],
                caja: '',
                cajass: this.cajass = [],
                cliente: '',
                clientess: this.clientess = [],
                estado: '',
                estadoss: this.estadoss = [],

                //variables interfaz de compra1
                bulInterfazCompra1: false,
                baseValorCompra: 0,
                ivaValorCompra: 0,
                totalCompra: 0,
                provedorCompra: '',
                numeroControlCompra: '',
                tipoCompra: '',
                comentariosCompra: '',
                codigoContableCompra: '',
                almacenCompra: '',
                fechaCompra: '',
                tipoRegistroCompra: '',
                tipossCompra: this.tipossCompra = [],
                nombreArticuloCompra: '',
                precioCompra: '',
                cantidadCompra: '',
                tipoIvaCompra: '',

                //variables interfaz de compra2
                bulInterfazCompra2: false,
                bulCreditoCompra: false,
                bulContadoCompra: false,
                bulCajaCompra: false,
                bulChequeCompra: false,
                bulTransferenciaCompra: false,
                condicionesPCompra: '',
                condicionessCompra: this.condicioness = [
                    "contado",
                    "credito"
                ],
                fechaVCompra: '',
                formaPCompra: '',
                formassCompra: this.formass = [
                    "transferencia",
                    "cheque",
                    "caja"
                ],
                TcajaCompra: '',
                bancoCCompra: '',
                numeroRCompra: '',
                beneficiarioCompra: '',
                fechaChequeCompra: '',
                conceptoCompra: '',
                numeroTCompra: '',

                //variables interfaz de venta1
                bulInterfazVenta1: false,
                baseValorVenta: 0,
                ivaValorVenta: 0,
                totalVenta: 0,
                clienteVenta: '',
                numeroControlVenta: '',
                tipoVenta: '',
                tipossVenta: this.tipossVenta = [
                    '1',
                    '2',
                    '3',
                    '4'
                ],
                comentariosVenta: '',
                codigoContableVenta: '',
                almacenVenta: '',
                fechaVenta: '',
                tipoRegistroVenta: '',
                nombreArticuloVenta: '',
                precioVenta: '',
                cantidadVenta: '',
                tipoIvaVenta: '',

                //variables interfaz de venta2
                bulInterfazVenta2: false,
                bulCreditoVenta: false,
                bulContadoVenta: false,
                bulCajaVenta: false,
                bulChequeVenta: false,
                bulTransferenciaVenta: false,
                condicionesPVenta: '',
                condicionessVenta: this.condicioness = [
                    "contado",
                    "credito"
                ],
                fechaVVenta: '',
                formaPVenta: '',
                formassVenta: this.formass = [
                    "transferencia",
                    "cheque",
                    "caja"
                ],
                TcajaVenta: '',
                bancoCVenta: '',
                numeroRVenta: '',
                beneficiarioVenta: '',
                fechaChequeVenta: '',
                conceptoVenta: '',
                numeroTVenta: '',

                //interfaz de exportar
                bulInterfazExportar: false,
                filtradoCuenta: '',
                seleccionFiltro: this.seleccionFiltro = [],
                formato: '',
            }
        },
        methods:{

            //actualizar filtros para mostrar en exportar

            actualizarFiltros(){
                this.seleccionFiltro = []
                if(this.desde !== ''){
                    this.seleccionFiltro.push(this.desde)
                }
                if(this.hasta !== ''){
                    this.seleccionFiltro.push(this.hasta)
                }
                if(this.tipo !== ''){
                    this.seleccionFiltro.push(this.tipo)
                }
                if(this.producto !== ''){
                    this.seleccionFiltro.push(this.producto)
                }
                if(this.provedor !== ''){
                    this.seleccionFiltro.push(this.provedor)
                }
                if(this.banco !== ''){
                    this.seleccionFiltro.push(this.banco)
                }
                if(this.caja !== ''){
                    this.seleccionFiltro.push(this.caja)
                }
                if(this.cliente !== ''){
                    this.seleccionFiltro.push(this.cliente)
                }
                if(this.estado !== ''){
                    this.seleccionFiltro.push(this.estado)
                }
            },

            //para volver a la vista anterior de compra

            vistaAnteriorCompra(){
                this.bulInterfazCompra2 = false
                this.bulInterfazCompra1 = true
                window.scroll(0,0)
            },
            
            //para volver a la vista anterior de venta

            vistaAnteriorVenta(){
                this.bulInterfazVenta2 = false
                this.bulInterfazVenta1 = true
                window.scroll(0,0)
            },

            //abrir siguiente apartado compra

            siguienApartadoCompra(){
                if(this.bulInterfazCompra2 === false){
                    this.bulInterfazCompra2 = true
                    this.bulInterfazCompra1 = false
                    window.scroll(0,0)
                }else{
                    this.bulInterfazCompra2 = false
                }
            },

            //abrir siguiente apartado venta

            siguienApartadoVenta(){
                if(this.bulInterfazVenta2 === false){
                    this.bulInterfazVenta2 = true
                    this.bulInterfazVenta1 = false
                    window.scroll(0,0)
                }else{
                    this.bulInterfazVenta2 = false
                }
            },

            //desplegar filtros de espacio inicial de cuenta

            abrirFiltros(){
                if(this.alturaFiltro === 0){
                    this.izquierdaDesplegable = -50
                    this.derechaDesplegable = 50
                    this.medidaBorde = 0
                    this.alturaFiltro = 250
                    this.paddingTop = 20
                }else{
                    this.izquierdaDesplegable = 50
                    this.derechaDesplegable = -50
                    this.medidaBorde = 10
                    this.alturaFiltro = 0
                    this.paddingTop = 0
                }
            },

            //abrir interfaz compra

            abrirInterfazCompra(){
                if(this.bulInterfazCompra1 === false && this.bulInterfazCompra2 === false){
                    this.bulInterfazCompra1 = true
                    window.scroll(0,0)
                }else{
                    this.bulInterfazCompra1 = false
                    this.bulInterfazCompra2 = false
                }
            },

            //abrir interfaz venta

            abrirInterfazVenta(){
                if(this.bulInterfazVenta1 === false && this.bulInterfazVenta2 === false){
                    this.bulInterfazVenta1 = true
                    window.scroll(0,0)
                }else{
                    this.bulInterfazVenta1 = false
                    this.bulInterfazVenta2 = false
                }
            },
            
            //abrir interfaz exportar

            abrirInterfazExportar(){
                if(this.bulInterfazExportar === false){
                    this.bulInterfazExportar = true
                    window.scroll(0,0)
                }else{
                    this.bulInterfazExportar = false
                }
            }

        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(100% + 290px);
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
    }
    .exportar-informacion{
        width: 60%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .cuadro-filtro-seleccionado{
        background: linear-gradient(153.24deg, rgba(0, 145, 142, 0.4) 9.04%, rgba(255, 255, 255, 0) 100%);
        width: 130px;
        padding:5px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom: 25px;
    }
    .margin-subir{
        margin-top:-80px;
    }
    .button-compra,.button-compra2,.button-venta,.button-venta2,.button-exportar{
        width: 50%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 25%;
    }
    .contenedor-botones-compra,.contenedor-botones-venta{
        margin-top:50px;
        width: 100%;
    }
    .button-compra2,.button-venta2,.button-exportar{
        width: 30%;
        margin-left: 0%;
    }
    .cuadro-estetico-compra,.cuadro-estetico-venta,.cuadro-estetico-exportar{
        background: #110133;
        width: 100%;
        height: 250px;
        position: absolute;
        bottom: -550px;
        left: 0px;
    }
    .cuadro-estetico-exportar{
        bottom: -448px;
    }
    .mar-top{
        margin-top: 0px;
    }
    .x-compra,.x-venta,.x-exportar{
        position: absolute;
        top:0px;
        width: 27.5px;
        height: 27.5px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/pictures/x-gris.svg);
        transition: all 0.5s;
        margin-top:25px;
        right: 25px;
        z-index: 10;
        cursor: pointer;
    }
    .texto-venta10,.texto-exportar,.texto-compra,.texto-compra2,.texto-compra3,.texto-compra4,.texto-compra5,.texto-venta,.texto-venta2,.texto-venta3,.texto-venta4,.texto-venta5{
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 40px;
        position: absolute;
        width: 100%;
        text-align: center;
        top:0px;
        margin-top: 25px;
    }
    .texto-compra2,.texto-venta2{
        top:390px;
    }
    .texto-compra3,.texto-venta3{
        top:650px;
        width: 33%;
        font-size: 35px;
    }
    .texto-compra4,.texto-venta4{
        top:650px;
        width: 33%;
        left: 33%;
        font-size: 35px;
    }
    .texto-compra5,.texto-venta5{
        top:650px;
        width: 33%;
        left:66%;
        font-size: 35px;
    }
    .texto-compra6,.texto-venta6{
        top:750px;
        width: 33%;
        font-size: 35px;
    }
    .texto-compra7,.texto-venta7{
        top:750px;
        width: 33%;
        left:33%;
        font-size: 35px;
    }
    .texto-compra8,.texto-venta8{
        top:750px;
        width: 33%;
        left:66%;
        font-size: 35px;
    }
    .texto-venta10{
        top:405px;
    }
    .indicador-posicion{
        width: 50%;
        height: 100px;
        margin-top:50px;
        margin-bottom:50px;
    }
    .paso1{
        background: rgba(0,145,145,0.6);
        height: 100px;
        width: 100px;
        border-radius: 50%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 30px;
    }
    .linea,.linea2{
        background:linear-gradient(90deg, rgba(0, 145, 142, 0.8) -8.77%, rgba(0, 145, 142, 0) 104.09%);
        width: 100px;
        height: 5px;
    }
    .linea2{
        transform: rotateZ(180deg);
    }
    .paso2{
        background: rgba(238, 238, 238, 0.8);
        height: 100px;
        width: 100px;
        border-radius: 50%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 30px;
    }
    .card-compra,.card-compra2,.card-venta,.card-venta2,.card-exportar{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.5) 100%);
        border-radius: 16px;
        height: 1000px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 70%;
        transition: all 1s;
        backdrop-filter: blur(8px);
        padding-top: 100px;
        position: relative;
    }
    .card-compra2,.card-venta2{
        height: 900px;
    }
    .card-exportar{
        height: 800px;
        margin-top:100px ;
    }
    .interfaz-compra,.interfaz-venta,.interfaz-compra2,.interfaz-venta2,.interfaz-exportar{
        position:absolute;
        z-index: 50;
        width: 100%;
        height: 1300px;
        top:0px;
        left:0px;
        backdrop-filter: blur(6px);
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%),rgba(255,255,255,0.8);
    }
    .interfaz-exportar{
        height: 1200px;
    }
    .label,.label2,.label3{
        font-family: Rubik;
        font-weight:bold ;
    }
    .label2{
        margin-left: 10%;
    }
    .label3{
        margin-left: 30%;
    }
    .margin-izquierda{
        margin-left:4%;
    }
    .input,.input2,.input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
    }
    .input2{
        margin-left:10%;
        width: 80%;
        padding-top: 0px;
    }
    .input3{
        margin-left:30%;
        width: 40%;
        padding-top: 0px;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 100px;
        margin-top:40px;
        border-radius:0px 10px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .caja-enunciado{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 50px;
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .contenedor-inicio{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .contenedor-cards-money{
        width: 80%;
        height: 300px;
        padding-left:25px ;
    }
    .card-money{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        height: 150px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 26%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top:105px;
        padding-top: 20px;
    }
    .texto-card-money,.texto-cantidad-card-money,.texto-card-resumen,.texto-nombre-card-resumen,.texto-resumen{
        font-family: Rubik;
        font-weight: bold;
        text-align: center;
        font-size: 30px;
        padding: 0px;
    }
    .texto-cantidad-card-money{
        font-size: 50px;
        margin-top:-5px ;
    }
    .texto-card-resumen{
        font-size: 40px;
        margin-top:10px ;
    }
    .texto-nombre-card-resumen{
        font-size: 15px;
        margin-top:-7.5px ;
    }
    .texto-resumen{
        font-size: 17px;
        margin:0px;
        padding: 0px;
        text-align: start;
        width: 100%;
        margin-top:15px;
    }
    .contenedor-derecha{
        width: 20%;
        height: 100vh;
        position: absolute;
        right:0px ;
    }
    .contenedor-resumen{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 380px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 80%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 100px;
    }
    .boton-compra,.boton-venta,.boton-exportar{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 55px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 80%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
    .boton-compra{
        border-bottom: 3px solid #DF2E2E;
    }
    .boton-venta{
        border-bottom: 3px solid #297F87;
    }
    .boton-exportar{
        border-bottom: 3px solid #110133;
    }
    .contenedor-cuenta-historial{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 500px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 71%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        position: relative;
    }
</style>